import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '../views/Dashboard'
import History from '../views/History'
import MapView from '../views/Map'
import Login from '../views/Login'
import store from '../store';

const requireAuthenticated = (to, from, next) => {
    store.dispatch('auth/initialize')
        .then(() => {
            if (!store.getters['auth/isAuthenticated']) {
                next('/login');
            } else {
                next();
            }
        });
};

const requireUnauthenticated = (to, from, next) => {
    store.dispatch('auth/initialize')
        .then(() => {
            if (store.getters['auth/isAuthenticated']) {
                next('/home');
            } else {
                next();
            }
        });
};

const redirectLogout = (to, from, next) => {
    store.dispatch('auth/logout')
        .then(() => next('/login'));
};


Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: DashboardView,
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/map',
        name: 'map',
        component: MapView,
        beforeEnter: requireAuthenticated
    },
    {
        path: '/login',
        component: Login,
        beforeEnter: requireUnauthenticated
    },
    {
        path: '/logout',
        beforeEnter: redirectLogout,
    },
    {
        path: '/history',
        name: 'history',
        component: History,
        beforeEnter: requireAuthenticated
    }
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router