<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md3
      >
        <v-card elevation="5">
          <v-form @submit.prevent="login(inputs)">
            <v-toolbar
              dark
              color="primary"
            >
              <v-toolbar-title>
                <div class="logo">
                  <i class="icon-tecsys" />
                  <span class="t1">
                    {{ $t('toolbar').title }}
                  </span>
                </div>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-5 pb-0">
              <v-alert
                v-if="error"
                dense
                type="warning"
              >{{ $t('login').error }}</v-alert>
              <v-text-field
                prepend-icon="mdi-account"
                name="login"
                label="Login"
                type="text"
                v-model="inputs.username"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
                v-model="inputs.password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                color="primary"
                class="ma-2"
              >
                <v-icon>mdi-location-enter</v-icon>
                Login
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      inputs: {
        username: '',
        password: '',
      },
      error: false,
    };
  },
  methods: {
    login ({ username, password }) {
      var that = this;
      that.error = false;
      this.$store.dispatch('auth/login', { username, password })
        .then(() => this.$router.push('/')
          .catch(() => {
            that.error = true;
            setTimeout(() => that.error = false, 2000);
          }));
    },
  },
  computed: mapGetters('auth', [
    'isError',
  ])
};
</script>
