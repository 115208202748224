import Vue from 'vue'

const LOAD_TYPES = 'LOAD_TYPES';
const CHANGE_TYPE = 'CHANGE_TYPE';
const LOAD_EVENTS = 'LOAD_EVENTS';
const FILTER_EVENTS = 'FLTER_EVENTS';
const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';
const CHANGE_PRODUCT = 'CHANGE_PRODUCT';
const FILTER_HAS_EVENT = "FILTER_HAS_EVENT";
const LOAD_POSITIONS = "LOAD_POSITIONS";
const LOAD_LOCATIONS = "LOAD_LOCATIONS";
const LOAD_TABLE_HEADER = "LOAD_TABLE_HEADER";
const LOADING = "LOADING";
const UPDATE_ACK = "UPDATE_ACK";
const FLAG_LOCATIONS = "FLAG_LOCATIONS";


const state = {
    types: [],
    typeSelected: '',
    events: [],
    eventsSelected: [],
    products: [],
    productSelected: {},
    filterHasEvent: true,
    positions: [],
    locations: [],
    header: [],
    loading: true,
    updateLocations: false,
};

const getters = {
    online: state => {
        return state.products.filter(p => p.online).length
    },
    offline: state => {
        return state.products.filter(p => !p.online).length
    },
    loading: state => {
        return state.loading
    }
};

const actions = {
    getTypes({ commit, dispatch }) {
        Vue.axios.get('/api/types/')
            .then(({ data }) => {
                commit(LOAD_TYPES, data);
                if (data.length > 0) {
                    const idx = data.findIndex(p => p._id === "5d3ee02e73a16b5f925cac54");
                    if (idx > 0) {
                        dispatch("changeType", data[idx])
                    } else {
                        dispatch("changeType", data[0])
                    }
                }
            });
    },
    changeType({ commit, dispatch }, type) {
        commit(CHANGE_TYPE, type);
        dispatch('getProducts', type._id);
        dispatch('getEvents', type._id);
    },
    getEvents({ commit }, id) {
        Vue.axios.get('/api/events/', { params: { 'typ': id } })
            .then(({ data }) => {
                commit(LOAD_EVENTS, data)
            });
    },
    getProducts({ commit }, id) {
        Vue.axios.get('/api/products/', { params: { 'typ': id } })
            .then(({ data }) => {
                commit(LOAD_PRODUCTS, data);
                if (data.length > 0)
                    commit(CHANGE_PRODUCT, data[0]);
                else
                    commit(LOADING, false);
            });
    },
    getLocations({ commit }) {
        return Vue.axios.get('/api/location/')
            .then(({ data }) => {
                commit(LOAD_LOCATIONS, data.locations)
                commit(LOAD_TABLE_HEADER, data.header)
                return data
            });
    },
    flagLocations({ commit }, value) {
        commit(FLAG_LOCATIONS, value);
    },
    ACK({ commit }, data) {
        return Vue.axios.post('/api/setAlarmACK/', null, { params: data })
            .then(({ data }) => {
                commit(UPDATE_ACK)
                return data
            });
    },
    changeProduct({ commit }, product) {
        commit(CHANGE_PRODUCT, product);
    },
    updateLocation({ commit }, message) {
        commit(UPDATE_LOCATIONS, message.data);
    },
    updateProduct({ commit }, message) {
        commit(UPDATE_PRODUCT, message.data);
    },
    getProduct({ commit }, id) {
        Vue.axios.get('/api/product/' + id + '/')
            .then(({ data }) => commit(CHANGE_PRODUCT, data));
    },
    filterEvents({ commit }, evts) {
        commit(FILTER_EVENTS, evts);
    },
    filterHasEvent({ commit }, evt) {
        commit(FILTER_HAS_EVENT, evt);
    },
    getPositions({ commit }) {
        Vue.axios.get('/api/position/')
            .then(({ data }) => commit(LOAD_POSITIONS, data));
    },
    loading({ commit }, value) {
        commit(LOADING, value);
    },
};

const mutations = {
    [LOAD_TYPES](state, types) {
        state.types = types;
    },
    [CHANGE_TYPE](state, type) {
        state.typeSelected = type;
    },
    [LOAD_EVENTS](state, events) {
        state.events = events;
        state.eventsSelected = events;
    },
    [LOAD_PRODUCTS](state, products) {
        state.products = products;
    },
    [LOAD_LOCATIONS](state, locations) {
        state.locations = locations;
    },
    [FLAG_LOCATIONS](state) {
        state.updateLocations = true;
    },
    [LOAD_TABLE_HEADER](state, header) {
        state.header = header
    },
    [UPDATE_LOCATIONS](state, location) {
        //console.log("update location", location)

        const idx = state.locations.findIndex(p => p._id === location._id)
        if (idx != -1)
            Object.assign(state.locations[idx], location)

        state.updateLocations = !state.updateLocations
    },
    [UPDATE_PRODUCT](state, product) {
        //console.log("update product", product)

        let sts = false
        let idx = state.locations.findIndex(p => p._id === product.location_id)
        if (idx != -1) {
            state.locations[idx].products.findIndex(p => {
                if (p._id === product._id) {
                    Object.assign(p, product)
                    sts = true
                }
            })
            if (sts == false)
                state.locations[idx].products.push(product)
        }

        idx = state.products.findIndex(p => p._id === product._id)
        if (idx != -1)
            Object.assign(state.products[idx], product)

        state.updateLocations = !state.updateLocations
    },
    [CHANGE_PRODUCT](state, product) {
        state.productSelected = product;
    },
    [FILTER_EVENTS](state, evts) {
        state.eventsSelected = evts;
    },
    [FILTER_HAS_EVENT](state, evt) {
        state.filterHasEvent = evt;
    },
    [LOAD_POSITIONS](state, data) {
        state.positions = data;
    },
    [LOADING](state, value) {
        state.loading = value;
    },
    [UPDATE_ACK](state) {
        state.ack = true;
    },
};

export default {
    namespaced: true,
    state: state,
    getters,
    actions,
    mutations,
};