import Vue from 'vue'
import route from '../router/index';
import i18n from '../i18n'

const TOKEN_STORAGE_KEY = 'TOKEN_STORAGE_KEY';
const LOGIN_BEGIN = 'LOGIN_BEGIN';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOCALE = 'LOCALE';
const LOGOUT = 'LOGOUT';


const initialState = {
    authenticating: false,
    error: false,
    token: null,
    language: '',
};

const getters = {
    isAuthenticated: state => !!state.token,
    isError: state => !!state.error,
    language: state => {
        return state.language
    },
};

const actions = {
    login({ commit }, { username, password }) {
        commit(LOGIN_BEGIN);
        return Vue.axios.post('/api/rest-auth/login/', { username, password })
            .then(({ data }) => {
                commit(LOGIN_SUCCESS, data.key)
            })
            .catch(() => commit(LOGIN_FAILURE));
    },
    logout({ commit }) {
        return Vue.axios.post('/api/rest-auth/logout/', {})
            .finally(() => commit(LOGOUT));
    },
    initialize({ commit }) {
        const token = localStorage.getItem(TOKEN_STORAGE_KEY);
        if (token) {
            commit(LOGIN_SUCCESS, token)
        }
    },
    locale({ commit }, value) {
        commit(LOCALE, value);
    }
};

const mutations = {
    [LOGIN_BEGIN](state) {
        state.authenticating = true;
        state.error = false;
    },
    [LOGIN_FAILURE](state) {
        state.authenticating = false;
        state.error = true;
    },
    [LOGIN_SUCCESS](state, token) {
        state.authenticating = false;
        state.error = false;
        localStorage.setItem(TOKEN_STORAGE_KEY, token);
        Vue.axios.defaults.headers.Authorization = `Token ${token}`;
        state.token = token;
        if (localStorage.getItem('Language')) {
            let lang = localStorage.getItem('Language')
            i18n.locale = lang
            state.language = lang;
        } else {
            let lang = 'pt'
            localStorage.setItem('Language', lang)
            i18n.locale = lang
            state.language = lang
        }
    },
    [LOGOUT](state) {
        state.authenticating = false;
        state.error = false;
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        delete Vue.axios.defaults.headers.Authorization;
        state.token = null;
        route.go('/');
    },
    [LOCALE](state, value) {
        state.language = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};