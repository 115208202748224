export default {
    filters: {
        Uppercase(value) {
            return value.toUpperCase()
        },
        ReplaceStr(value, v1, v2) {
            return value.replace(v1, v2)
        },
        Letter(value) {
            return value.split('')[0]
        },
    }
}