<template>
  <v-container>
    <v-card
      elevation="10"
      class="mb-5"
    >
      <GChart
        type="BarChart"
        :data="chartData"
        :options="chartOptions"
      />
    </v-card>
    <v-card elevation="10">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('dashboard').search"
          single-line
          hide-details
          class="ma-5"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-5"
        >
          <download-excel
            :data="products"
            :fields="download_fields"
            name="Dispositivos.xls"
            :meta="json_meta"
          >
            <v-icon>mdi-download</v-icon>
            download
          </download-excel>
        </v-btn>

      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="products"
        :search="search"
        @click:row="openHistoric"
        class="row-pointer"
        :footer-props="{
            'items-per-page-options': [10, 25, 50, 100, 250],
            'showFirstLastPage': true,
            'show-current-page': true,
            'items-per-page-text':$t('vuetify-table').items_per_page_text
          }"
        :items-per-page="25"
        :loading="showLoading"
        :loading-text="$t('history').loading"
        :custom-sort="customSort"
        multi-sort
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { GChart } from 'vue-google-charts'

export default {
  name: 'DashboardView',
  components: {
    GChart
  },
  data () {
    return {
      search: '',
      showLoading: this.$store.getters['product/loading'],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      chartOptions: {
        legend: 'none',
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        }
      }
    }
  },
  watch: {
    productSelected () {
      this.$store.dispatch('product/loading', false)
    },
    loading () {
      this.showLoading = this.$store.getters['product/loading']
    }
  },
  computed: {
    headers: {
      get: function () {
        let locale = this.$i18n.locale
        let aux = []

        this.$store.state.product.eventsSelected.forEach((p) => {
          let datetime = ''
          if(locale == 'en')
            datetime = this.$i18n.messages[locale].dashboard.datetime + ' (' + p.name_en + ')'
          
          else if(locale == 'es')
            datetime = this.$i18n.messages[locale].dashboard.datetime + ' (' + p.name_es + ')'

          else if(locale == 'pt')
            datetime = this.$i18n.messages[locale].dashboard.datetime + ' (' + p.name + ')'

          if (p.show_datetime) {
            aux.push({
              text: datetime,
              value: p.code.toString() + '_' + 'datetime',
            });
          }

          let text
          if(locale == 'en')
            text = p.name_en
          else if(locale == 'es')
            text = p.name_es
          else if(locale == 'pt')
            text = p.name

          aux.push({
            text: text,
            value: p.code.toString(),
          });
        });


        aux = aux.sort((a, b) => a.value - b.value);
        let location = this.$i18n.messages[locale].dashboard.location 
        aux.unshift({
          text: location,
          value: "locationId"
        });


        let device = this.$i18n.messages[locale].dashboard.device 
        aux.unshift({
          text: device,
          value: "sigfox"
        });
        return aux;
      }
    },
    download_fields: {
      get: function () {
        const aux = {};
        aux["Device"] = "sigfox";
        aux["Localização"] = "locationId";
        let locale = this.$i18n.locale
        let name
        if(locale == 'en')
          name = 'name_en'
        else if(locale == 'es')
          name = 'name_es'
        else if(locale == 'pt')
          name = 'name'
        this.$store.state.product.eventsSelected.forEach(e => {
          aux[e[name]] = e.code.toString()
        });
        return aux;
      }
    },
    chartData: {
      get: function () {
        return [
          ['Sensor', 'Status', { role: 'style' }, { role: "annotation" }],
          ['Online', this.online, '#109618', this.online],
          ['Offline', this.offline, '#DC3912', this.offline],
        ]
      }
    },
    products: {
      get: function () {
        let products = this.$store.state.product.products;
        if (this.filterHasEvent) {
          products = products.filter((p) => p.status != null);
        }

        let locale = this.$i18n.locale
        return products.map((p) => {
          if (p.status) {
            let verbose_value
            if(locale == 'en')
              verbose_value = 'verbose_value_en'
            else if(locale == 'es')
              verbose_value = 'verbose_value_es'
            else if(locale == 'pt')
              verbose_value = 'verbose_value'
            return Object.assign(p, ...p.status.map((s) => ({
                [s.type_code]: s[verbose_value],
                [s.type_code + '_' + 'datetime']: Vue.moment(s.datetime).format("DD/MM/YYYY - HH:mm:ss"),
              })))
          } else {
            return p;
          }
        })
      }
    },
    ...mapState('product', [
      'productSelected',
      'eventsSelected',
      'filterHasEvent',
      'loading',
    ]),
    ...mapGetters('product', [
      'online',
      'offline'
    ])
  },
  mounted() {
    document.getElementsByClassName('box-legend')[0].style.display='none'
  },
  methods: {
    openHistoric (product) {
      this.$store.dispatch('product/changeProduct', product, { root: true });
      this.$router.push({ path: 'history' })
    },
    customSort: function (items, sortBy, sortDesc) {
      if (sortBy === null || !sortBy.length) return items
      const stringCollator = new Intl.Collator('pt-br', { sensitivity: 'accent', usage: 'sort' })
      return items.sort((a, b) => {
        for (let i = 0; i < sortBy.length; i++) {

          let sortA = a[sortBy[i]]
          let sortB = b[sortBy[i]]

          if (sortDesc[i]) {
            [sortA, sortB] = [sortB, sortA]
          }
          // Check if both cannot be evaluated
          if (sortA === null && sortB === null) {
            continue
          }

          [sortA, sortB] = [sortA, sortB].map(s => (s || '').toString().toLocaleLowerCase().replace(',', '.'))

          if (sortA !== sortB) {
            if (sortBy[i].endsWith('_datetime')) {
              return Vue.moment(sortA, "DD/MM/YYYY - HH:mm:ss") - Vue.moment(sortB, "DD/MM/YYYY - HH:mm:ss")

            } else if (!isNaN(sortA) && !isNaN(sortB)) {
              return Number(sortA) - Number(sortB)
            }
            return stringCollator.compare(sortA, sortB)
          }
        }
        return 0
      })
    },
  }
}
</script>
<style>
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
</style>