<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      v-if="isAuthenticated"
      app
    >
      <SideBar />
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      v-if="isAuthenticated"
    >

      <!-- <v-container>
        <v-row> -->
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <div class="logo">
        <i class="icon-tecsys" />
        <span class="t1">
          {{ $t('toolbar').title }}
        </span>
      </div>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
                @click="datatable()"
              >
                <v-icon>mdi-chart-arc</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('toolbar').datatable }}</span>
          </v-tooltip>
        </template>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
                @click="map()"
              >
                <v-icon>mdi-map-marker-radius</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('toolbar').location }}</span>
          </v-tooltip>
        </template>
      </v-menu>

      <!-- <v-menu offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>mdi-access-point</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('toolbar').alarmsConfig }}</span>
          </v-tooltip>
        </template>
      </v-menu> -->

      <v-menu offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
                href="/api"
                target="_blank"
              >
                <v-icon>mdi-api</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('toolbar').api }}</span>
          </v-tooltip>
        </template>
      </v-menu>

      <v-menu
        offset-y
        class="lang"
      >
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-img
                  :src="langIMG"
                  max-width="24"
                ></v-img>
              </v-btn>
            </template>
            <span>{{ $t('toolbar').language }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="language in languages"
            :key="language.id"
            @click="setLocale(language.id)"
          >
            <v-list-item-avatar
              tile
              size="24"
            >
              <v-img :src="language.flagSrc"></v-img>
            </v-list-item-avatar>
            <v-list-item-title>{{ language.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('toolbar').more }}</span>
          </v-tooltip>
        </template>
        <v-list width="140">
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
          >
            <v-list-item
              link
              @click="openProfile"
            >
              <v-icon size="32">mdi-account-circle</v-icon>
              <v-list-item-title>{{ $t('toolbar').user }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              @click="dialogHelp = true"
            >
              <v-icon class="icon">mdi-lifebuoy</v-icon>
              <v-list-item-title>{{ $t('toolbar').help }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              @click="dialogAbout = true"
            >
              <v-icon class="icon">mdi-information</v-icon>
              <v-list-item-title>{{ $t('toolbar').about }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              @click="logout()"
            >
              <v-icon class="icon">mdi-logout</v-icon>
              <v-list-item-title>{{ $t('toolbar').exit }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view />

      <v-dialog
        v-model="statusProfile"
        persistent
        max-width="600px"
      >
        <Profile v-if="statusProfile" />
      </v-dialog>

      <v-dialog
        v-model="dialogAbout"
        v-if="dialogAbout"
        max-width="600px"
      >
        <v-system-bar
          color="primary"
          class="pa-10"
        >
          <v-card-title class="white--text">{{ $t('toolbar').about.toUpperCase()  }}</v-card-title>
        </v-system-bar>
        <About />
      </v-dialog>

      <v-dialog
        v-model="dialogHelp"
        v-if="dialogHelp"
        max-width="350"
      >
        <v-system-bar
          color="primary"
          class="pa-3"
        >
        </v-system-bar>
        <Help />
      </v-dialog>

    </v-main>

    <v-snackbar
      v-model="snackbar"
      :vertical="vertical"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="indigo"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('buttons').close }}
        </v-btn>
      </template>
    </v-snackbar>

    <Footer />
  </v-app>
</template>

<script>
import SideBar from '@/components/SideBar';
import Footer from '@/components/Footer.vue'
import Profile from '@/components/Profile.vue'
import About from '@/components/About.vue'
import Help from '@/components/Help.vue'
import { mapGetters, mapState } from 'vuex';
import imgUS from '@/assets/images/us.png'
import imgBR from '@/assets/images/br.png'
import imgES from '@/assets/images/es.png'
import router from './router';
import store from './store'

export default {
  name: 'App',
  components: {
    SideBar,
    Profile,
    About,
    Help,
    Footer
  },
  data () {
    let locale = this.$i18n.locale
    let lang = this.$i18n.messages[locale]
    let img = undefined

    if(lang == 'en')
      img = imgUS
    else if(lang == 'pt')
      img = imgBR
    else if(lang == 'es')
      img = imgES
    

    return {
      dev: (process.env.VUE_APP_DEV == 'true') ? true : false,
      snackbar: false,
      text: '',
      vertical: true,
      drawer: false,
      langIMG: img,
      username: '',
      dialogHelp: false,
      dialogAbout: false,
      selectedItem: 0,
      languages: [
        {
          id: 'en',
          title: this.$i18n.messages[localStorage.getItem('Language')].language['en'],
          flagSrc: imgUS
        },
        {
          id: 'pt',
          title: this.$i18n.messages[localStorage.getItem('Language')].language['pt'],
          flagSrc: imgBR
        },
        {
          id: 'es',
          title: this.$i18n.messages[localStorage.getItem('Language')].language['es'],
          flagSrc: imgES
        }
      ]
    }
  },
  beforeCreate () {
    if (localStorage.getItem('Language')) {
      let lang = localStorage.getItem('Language')
      this.$i18n.locale = lang
      store.dispatch('auth/locale', lang)
    } else {
      let lang = 'pt'
      localStorage.setItem('Language', lang)
      this.$i18n.locale = lang
      store.dispatch('auth/locale', lang)
    }
  },
  mounted () {
    let locale = this.$i18n.locale
    let lang = this.$i18n.messages[locale]

    if(locale == 'en')
      this.langIMG = imgUS
    else if(locale == 'pt')
      this.langIMG = imgBR
    else if(locale == 'es')
      this.langIMG = imgES

    document.title = lang.toolbar.name
  },
  watch: {
    callback () {
      let msg = store.getters['profile/callback'].msg
      this.text = msg
      this.snackbar = true
      store.dispatch('profile/statusProfile', false);
    }
  },
  computed: {
    ...mapState('profile', ['statusProfile', 'callback']),
    ...mapGetters('auth', ['isAuthenticated'])
  },
  methods: {
    datatable () {
      router.push('/home')
    },
    map () {
      router.push('/map')
    },
    logout () {
      store.dispatch('auth/logout');
    },
    setLocale (id) {
      this.$i18n.locale = id
      localStorage.setItem('Language', id)
      let locale = this.$i18n.locale

      if(locale == 'en')
        this.langIMG = imgUS
      else if(locale == 'pt')
        this.langIMG = imgBR
      else if(locale == 'es')
        this.langIMG = imgES

      this.languages = [
        {
          id: 'en',
          title: this.$i18n.messages[localStorage.getItem('Language')].language['en'],
          flagSrc: imgUS
        },
        {
          id: 'pt',
          title: this.$i18n.messages[localStorage.getItem('Language')].language['pt'],
          flagSrc: imgBR
        },
        {
          id: 'es',
          title: this.$i18n.messages[localStorage.getItem('Language')].language['es'],
          flagSrc: imgES
        }
      ]
    },
    openProfile () {
      store.dispatch('profile/getUser', null, { root: true })
      store.dispatch('profile/statusProfile', true)
    },
  },
};
</script>

<style>
@import "./assets/css/icon-tecsys.css";

.logo {
  position: relative;
  height: 50px;
  margin: 0;
  padding: 0px 15px;
  text-align: right;
  top: 2px;
}

.logo i {
  color: rgb(255, 255, 255);
  font-size: 40px;
}

.logo span {
  position: absolute;
  font-size: 11px;
  font-style: italic;
  letter-spacing: 0.3px;
  color: #fff;
  top: 32px;
  right: 15px;
}

.v-list-item img {
  margin-right: 5px;
}
.v-badge {
  top: 5px;
  left: -6px;
}

.v-badge span {
  border: solid 1px rgb(25, 118, 210) !important;
}

.v-icon.icon {
  margin-right: 5px;
}

.v-btn.profile span.name {
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  text-transform: capitalize;
  top: 25px;
  right: 5px;
}
.lang {
  width: 120px;
}
</style>