<template>
  <v-container>
    <v-card>
      <v-card-title class="pa-5">
        {{ $t('dashboard').title + ': ' + productSelected.sigfox }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="download"
        >
          <v-icon>mdi-download</v-icon>
          Download
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="products"
        :options.sync="options"
        :server-items-length="totalProducts"
        :loading="loading"
        :loading-text="$t('history').loading"
        locale="pt"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, 250],
          'showFirstLastPage': true,
          'show-current-page': true,
          'items-per-page-text':$t('vuetify-table').items_per_page_text
        }"
        :items-per-page="25"
        multi-sort
      >
        <template v-slot:item.datetime="{ item }">
          {{ item.datetime | moment("DD/MM/YYYY - HH:mm:ss") }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
var qs = require('qs');

export default {
  name: 'ProductHistory',
  data () {
    let locale = this.$i18n.locale
    let name
    let verbose_value

    if(locale == 'en'){
      name = 'evt.name_en'
      verbose_value = 'verbose_value_en'
    }else if(locale == 'es'){
      name = 'evt.name_es'
      verbose_value = 'verbose_value_es'
    }else if(locale == 'pt'){
      name = 'evt.name'
      verbose_value = 'verbose_value'
    }
    return {
      loading: true,
      options: {},
      headers: [
        {
          text: this.$i18n.messages[locale].history.type,
          align: 'start',
          value: name,
        },
        {
          text: this.$i18n.messages[locale].history.datetime,
          value: 'datetime'
        },
        {
          text: this.$i18n.messages[locale].history.value,
          value: verbose_value
        },
      ],
      totalProducts: 0,
      products: [],
    }
  },
  updated () {
    let locale = this.$i18n.locale
    let name
    let verbose_value

    if(locale == 'en'){
      name = 'evt.name_en'
      verbose_value = 'verbose_value_en'
    }else if(locale == 'es'){
      name = 'evt.name_es'
      verbose_value = 'verbose_value_es'
    }else if(locale == 'pt'){
      name = 'evt.name'
      verbose_value = 'verbose_value'
    }

    this.$set(this.headers[0], 'text', this.$i18n.messages[locale].history.type)
    this.$set(this.headers[0], 'value', name)
    this.$set(this.headers[1], 'text', this.$i18n.messages[locale].history.datetime)
    this.$set(this.headers[2], 'text', this.$i18n.messages[locale].history.value)
    this.$set(this.headers[2], 'value', verbose_value)
  },
  computed: {
    ...mapState('product', [
      'productSelected',
      'eventsSelected',
    ])
  },
  watch: {
    eventsSelected () {
      this.loadHistory()
    },
    options: {
      handler () {
        this.loadHistory()
      },
      deep: true,
    },
  },
  methods: {
    loadHistory () {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const filter = this.eventsSelected.map((evt) => evt.code)
      let promise = Vue.axios.get(
        '/api/historic/', {
        params: {
          product: this.productSelected._id,
          page: page,
          page_size: itemsPerPage,
          sortBy: sortBy,
          sortDesc: sortDesc,
          filter: filter
        },
        paramsSerializer: params => {
          return qs.stringify(params, { indices: false })
        }
      });
      return promise.then((data) => {
        const items = data.data;
        this.products = items.results;
        this.totalProducts = items.count;
        this.loading = false;
      })
    },
    download () {
      let locale = this.$i18n.locale
      window.open('/datapoints/?' + 'produto=' + this.productSelected._id + '&locale=' + locale);
    }
  },
  mounted () {
    this.loadHistory();
  }
}
</script>