<template>
  <v-card
    class="mx-auto pa-10"
    tile
  >
    <v-row class="d-flex flex-column ">
      <img
        :src="logoHabitat"
        alt="habitat"
        width="190"
        class="px-5"
      >
      <v-card-subtitle class="pa-1 pb-5 px-5">{{ $t('about').app }} @ 2019</v-card-subtitle>
    </v-row>

    <div>

    </div>

    <v-card-text class="pa-3 text-justify">
      {{ $t('about').text }}
    </v-card-text>
    <v-card-title class="px-3 text-subtitle-1"><small>{{ $t('about').version }} v1.2.0</small></v-card-title>
  </v-card>
</template>
<script>
import logo from '../assets/json/habitat.json'

export default {
  name: 'About',
  data () {
    //let locale = this.$i18n.locale
    return {
      logoHabitat: logo.habitat,
    }
  }
}
</script>
<style scoped>
.v-tabs.tabs .v-icon {
  margin-right: 5px;
}
</style>