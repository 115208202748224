import Vue from 'vue'
import VueNativeSock from 'vue-native-websocket'
import store from '../store'

var url;

if (window.location.protocol == 'https:') {
    url = 'wss://';
} else {
    url = 'ws://';
}

url = url + window.location.host + '/ws/product/';

Vue.use(VueNativeSock, url, { 
    store: store,
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 0, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000),
    format: 'json'
})
