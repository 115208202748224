import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import JsonExcel from "vue-json-excel"
import VueGoogleCharts from 'vue-google-charts'
import router from './router'
import i18n from './i18n'
import VueI18n from 'vue-i18n'
import store from './store'
import './plugins/vue-native-sock'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.use(VueI18n)
Vue.use(require('vue-moment'));
Vue.use(VueGoogleCharts);
Vue.component("downloadExcel", JsonExcel);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')