<template>
  <footer>
    <div class="box-legend">
      <div>
        <v-icon 
          dark 
          color="black"
        >
          mdi-map-marker
        </v-icon>
        <label>Offline</label>
      </div>
      <div>
        <v-icon 
          dark 
          color="green"
        >
          mdi-map-marker
        </v-icon>
        <label>Operando</label>
      </div>
      <div>
        <v-icon 
          dark 
          color="red"
        >
          mdi-map-marker
        </v-icon>
        <label>Atenção</label>
      </div>
      <div>
        <v-icon 
          dark 
          color="blue"
        >
          mdi-map-marker
        </v-icon>
        <label>Aguardando Evt</label>
      </div>
    </div>
    <span>
      Tecsys do Brasil &copy; 2020
      <img src="./../assets/images/br.svg">
    </span>
  </footer>
</template>

<script>
export default {}

</script>

<style scope>
footer .box-legend{
  width: 100%;
  height: 20px;
  color: #fff;
  font-family: roboto;
  letter-spacing: 0.5px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
footer .box-legend > div{
  width: 120px;
  text-align: center;
}

footer .box-legend > div label{
  width:100%;
  margin-left: 5px;
}

footer .v-icon.v-icon{
  font-size: 18px;
  background: rgba(254, 254, 254, 0.078);
  padding: 3px 2px 2px 3px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

footer {
  position: absolute;
  width: 100%;
  height: 30px !important;
  padding: 5px 8px;
  font-size: 11px;
  background-color: rgb(25, 54, 90);
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  bottom: 0px !important;
  z-index: 999999999999;
}

footer span {
  position: absolute;
  width: 150px;
  text-align: right;
  right: 5px;
}

footer img {
  width: 20px;
  vertical-align: bottom;
}

@media (max-width: 1140px) {
  footer {
    width: 100%;
  }
}
@media (max-width: 750px) {
  footer span{ display: none }
  footer .box-legend{
    width: 100%;
    margin: 0 ;
  }
  footer .box-legend > div{
    width: 180px;
    margin: 0;
  }
} 
</style>
