import Vue from 'vue'
import i18n from '../i18n'
import store from '../store/'
import avatar from '../assets/json/habitat.json'

const STATUS_PROFILE = 'STATUS_PROFILE'
const LOAD_PROFILE = 'LOAD_PROFILE'
const UPDATE_PROFILE = 'UPDATE_PROFILE'
const LOAD_AVATAR = 'LOAD_AVATAR'
const CALLBACK = "CALLBACK"

const state = {
    profile: [],
    avatar: avatar.anonymous,
    avatarAnonymous: avatar.anonymous,
    statusProfile: false,
    callback: [],
}

const getters = {
    avatar: state => {
        return state.avatar
    },
    profile: state => {
        return state.profile
    },
    statusProfile: state => {
        return state.statusProfile
    },
    callback: state => {
        return state.callback
    },
}

const actions = {
    statusProfile({ commit }, value) {
        commit(STATUS_PROFILE, value)
    },
    getUser({ commit }) {
        return Vue.axios.get('/api/rest-auth/user/')
            .then(({ data }) => {
                commit(UPDATE_PROFILE, data)
                return data
            });
    },
    getAvatar({ commit }) {
        Vue.axios.get('/api/avatar/')
            .then(({ data }) => {
                let d = (data.length > 0)? data[0] : data
                commit(LOAD_AVATAR, d)
                return data
            })
    },
    updateProfile({ commit }, params) {
        Vue.axios.patch('/api/rest-auth/user/', params)
            .then((res) => {
                if (res.status == 200) {
                    let locale = i18n._vm.locale
                    let lang = i18n._vm.messages[locale]

                    let data = {
                        "success": true,
                        "msg": lang.messages.requestSuccess
                    }
                    store.dispatch('profile/callbackEvent', data)
                    commit(UPDATE_PROFILE, res.data)
                }
            })
    },
    changePassword({ commit }, params) {
        Vue.axios.post('/api/rest-auth/password/change/', params)
            .then((res) => {
                if (res.status == 200)
                    store.dispatch('auth/logout')
                commit('')
            }).catch(err => {
                if (err.response.status === 400) {
                    let locale = i18n._vm.locale
                    let lang = i18n._vm.messages[locale]
                    let data = {
                        "success": false,
                        "msg": lang.messages.invalidPass
                    }
                    store.dispatch('profile/callbackEvent', data)
                    throw new Error(data.msg)
                }
                throw err
            })
    },
    callbackEvent({ commit }, data) {
        commit(CALLBACK, data)
    }
}

const mutations = {
    [STATUS_PROFILE](state, value) {
        state.statusProfile = value
    },
    [LOAD_PROFILE](state, value) {
        state.profile = value
    },
    [UPDATE_PROFILE](state, value) {
        state.profile = value
    },
    [LOAD_AVATAR](state, value) {
        state.avatar = value
    },
    [CALLBACK](state, data) {
        state.callback = data
    },
}

export default {
    namespaced: true,
    state: state,
    getters,
    actions,
    mutations,
}