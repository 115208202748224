<template>
  <v-sheet
    color="grey lighten-4"
    class="pa-4"
  >
    <v-row class="user">
      <v-col>
        <v-avatar class="mr-2">
          <img
            :src="avatarSelected"
            alt="avatar"
          >
        </v-avatar>
        {{ profile.username }}
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-combobox
      :label="$t('sideBar').deviceModel"
      :items="types"
      v-model="typeSelected"
      item-text="name"
      outlined
    >
    </v-combobox>
    <v-row>
      <v-col>
        <h4>{{ $t('sideBar').filter }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="filterHasEvent"
          :label="$t('sideBar').withEvents"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="eventsAllSelected"
          :indeterminate="eventsIndeterminate"
          :label="$t('sideBar').all"
          @change="toggleAll"
        ></v-checkbox>
      </v-col>

    </v-row>
    <v-list shaped>
      <v-list-item-group
        v-model="eventsSelected"
        multiple
      >
        <template v-for="(item, i) in events">
          <v-list-item
            :key="`item-${i}`"
            :value="item"
            active-class="blue--text text--accent-4"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.name_en"
                  v-if="lang == 'en'"
                ></v-list-item-title>

                <v-list-item-title
                  v-text="item.name_es"
                  v-else-if="lang == 'es'"
                ></v-list-item-title>

                <v-list-item-title
                  v-text="item.name"
                  v-else
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  color="blue accent-4"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-sheet>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import store from '../store'

export default {
  name: 'SideBar',
  data () {
    let avatar = store.state.profile.avatar.avatar
    let anonymous = store.state.profile.avatarAnonymous
    return {
      lang: this.locale,
      eventsAllSelected: true,
      eventsIndeterminate: false,
      avatarSelected: (avatar == '' || avatar == undefined) ? anonymous : avatar,
    }
  },
  created () {
    store.dispatch('profile/getUser', null, { root: true })
    store.dispatch('profile/getAvatar', null, { root: true })
    store.dispatch('product/getTypes', null, { root: true })
  },
  updated () {
    this.lang = this.$i18n.locale
  },
  watch: {
    avatar () {
      let avatar = store.state.profile.avatar.avatar
      let anonymous = store.state.profile.avatarAnonymous
      this.avatarSelected = (avatar == '' || avatar == undefined) ? anonymous : avatar
    }
  },
  computed: {
    ...mapGetters('profile', ["profile", "avatar"]),
    typeSelected: {
      get: function () {
        return this.$store.state.product.typeSelected
      },
      set: function (type) {
        store.dispatch('product/loading', true)
        store.dispatch('product/changeType', type, { root: true })
      }
    },
    eventsSelected: {
      get: function () {
        //console.log(this.$store.state.product.eventsSelected)
        return this.$store.state.product.eventsSelected
      },
      set: function (evts) {
        if (evts.length === 0) {
          this.eventsIndeterminate = false
          this.eventsAllSelected = false
        } else if (evts.length === this.events.length) {
          this.eventsIndeterminate = false
          this.eventsAllSelected = true
        } else {
          this.eventsIndeterminate = true
          this.eventsAllSelected = false
        }
        store.dispatch('product/filterEvents', evts, { root: true })
      }
    },
    filterHasEvent: {
      get: function () {
        return this.$store.state.product.filterHasEvent;
      },
      set: function (evt) {
        store.dispatch('product/filterHasEvent', evt, { root: true })
      }
    },
    ...mapState('product', [
      'types',
      'events',
      'products',
    ]),
  },
  methods: {
    toggleAll (checked) {
      this.eventsSelected = checked ? this.events : []
    },
  },
}
</script>
<style>
.v-navigation-drawer {
  z-index: 99 !important;
}
.user .col {
  margin-top: 10px;
  font-size: 14px;
  text-transform: capitalize;
}
.v-icon.icon {
  font-size: 30px;
}
.v-divider {
  margin: 20px 0 30px 0;
}
.v-sheet.theme--light {
  height: 100%;
}
</style>