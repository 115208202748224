var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"pa-5"},[_vm._v(" "+_vm._s(_vm.$t('dashboard').title + ': ' + _vm.productSelected.sigfox)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.download}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Download ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"options":_vm.options,"server-items-length":_vm.totalProducts,"loading":_vm.loading,"loading-text":_vm.$t('history').loading,"locale":"pt","footer-props":{
        'items-per-page-options': [10, 25, 50, 100, 250],
        'showFirstLastPage': true,
        'show-current-page': true,
        'items-per-page-text':_vm.$t('vuetify-table').items_per_page_text
      },"items-per-page":25,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.datetime",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.datetime,"DD/MM/YYYY - HH:mm:ss"))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }