import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './auth'
import Product from './product'
import Profile from './profile'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        }
    },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
        },
        SOCKET_ONCLOSE(state) {
            state.socket.isConnected = false
        },
        SOCKET_ONERROR(state, event) {
            // eslint-disable-next-line no-console
            console.error(state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            // eslint-disable-next-line no-console
            console.info(state, message)
            state.socket.message = message
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            // eslint-disable-next-line no-console
            console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },
    },
    actions: {},
    modules: {
        'auth': Auth,
        'product': Product,
        'profile': Profile,
    }
})