<template>
    <v-card
      class="mx-auto"
      width="400"
      tile
    >
      <v-navigation-drawer 
        width="100%"
        permanent>
        <v-list>
          <v-list-item>
              <i class="icon-tecsys" style="font-size:60px; color:#1b456e" />
            <v-list-item-avatar>
            </v-list-item-avatar>
          </v-list-item>
  
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h7">
                TECSYS DO BRASIL
              </v-list-item-title>
              <v-list-item-subtitle>tecsys@tecsysbrasil.com.br</v-list-item-subtitle>
            </v-list-item-content>
  
            <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-card>

</template>
<script>

export default {
  name: 'Help',
  data () {
    let locale = this.$i18n.locale
    return {
      selectedItem: 0,
      items: [
        { text: '+55 (12) 3797-8800', icon: 'mdi-phone' },
        { text: 'R. Orós, 146 - Parque Industrial', icon: 'mdi-crosshairs-gps' },
        { text: (locale == 'en')? 'São José dos Campos, SP - Brazil' : 'São José dos Campos, SP - Brasil', icon: 'mdi-map-marker-radius' },
        { text: (locale == 'en')? 'Work hours, 7:30AM to 5:30PM' : 'Horário Comercial, 7h30 às 17h30', icon: 'mdi-clock' },
        { text: 'http://www.tecsysbrasil.com.br', icon: 'mdi-application-import' }
      ],
    }
  }
}
</script>
<style scoped>
.v-tabs.tabs .v-icon {
  margin-right: 5px;
}
</style>