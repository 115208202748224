<template>
  <v-card>
    <v-tabs
      v-model="tab"
      background-color="primary accent-4"
      center-active
      dark
      class="tabs"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        <v-icon>mdi-account-circle</v-icon> {{ $t('profile').title1 }}
      </v-tab>
      <v-tab href="#tab-2">
        <v-icon>mdi-lock</v-icon> {{ $t('profile').title2 }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text class="pa-5">
            <v-form
              ref="form1"
              v-model="valid"
              lazy-validation
            >
              <v-card-text class="mt-5 ml-2">
                <v-container>
                  <v-row>
                    <v-avatar
                      class="avatar"
                      size="100"
                    >
                      <img
                        :src="avatarSelected"
                        alt="avatar"
                      >
                    </v-avatar>
                    <v-btn
                      class="av-btn mx-0"
                      fab
                      dark
                      small
                      color="#00000099"
                      @click="avImage()"
                    >
                      <v-icon dark>
                        mdi-camera
                      </v-icon>
                    </v-btn>
                  </v-row>
                  <v-form
                    ref="addImageForm"
                    lazy-validation
                  >
                    <v-file-input
                      accept="image/png, image/jpeg, image/bmp"
                      label="Avatar"
                      class="mt-3 av-file"
                      @change="loadImage"
                    >
                    </v-file-input>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        :label="$t('profile').firstName"
                        :counter="15"
                        :rules="nameRules"
                        v-model="profile.first_name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        :label="$t('profile').lastName"
                        v-model="profile.last_name"
                        :counter="15"
                        :rules="nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :label="$t('profile').username"
                        :counter="15"
                        :rules="nameRules"
                        v-model="profile.username"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :label="$t('profile').email"
                        :rules="emailRules"
                        v-model="profile.email"
                        required
                      ></v-text-field>
                      <small>{{ $t('profile').requiredFiled }}</small>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeProfile"
                >
                  {{ $t('buttons').close }}
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="setProfile"
                >
                  {{ $t('buttons').save }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-card flat>
          <v-card-text>
            <v-form
              ref="form2"
              v-model="valid"
              lazy-validation
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentPassword"
                        :label="$t('profile').currentPass"
                        :rules="passwordRules"
                        type="password"
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="newPassword"
                        :label="$t('profile').newPassword"
                        :rules="passwordRules"
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="confirmPassword"
                        :label="$t('profile').confirmPass"
                        :rules="passwordRules"
                        type="password"
                        required
                      ></v-text-field>
                      <small>{{ $t('profile').requiredFiled }}</small>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeProfile"
                >
                  {{ $t('buttons').close }}
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="changePassword"
                >
                  {{ $t('buttons').save }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>

import store from '../store'
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  data () {
    let avatar = store.state.profile.avatar.avatar
    let anonymous = store.state.profile.avatarAnonymous
    return {
      tab: null,
      valid: true,
      avatarSelected: (avatar == '' || avatar == undefined) ? anonymous : avatar,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  },
  watch: {
    avatar () {
      this.avatarSelected = this.avatar.avatar
    }
  },
  computed: {
    ...mapGetters('profile', ["profile", "avatar"]),
    profile () {
      let data = store.getters['profile/profile']
      return data
    },
    nameRules () {
      let locale = this.$i18n.locale
      let data = [
        v => !!v || ((locale == 'en') ? 'Field is required' : 'Campo é obrigatório'),
        v => (v && v.length <= 15) || ((locale == 'en') ? 'Name must be less than 16 characters' : 'O nome deve ter menos de 16 caracteres'),
      ]
      return data
    },
    emailRules () {
      let locale = this.$i18n.locale
      let data = [
        v => !!v || ((locale == 'en') ? 'Field is required' : 'Campo é obrigatório'),
        v => /.+@.+\..+/.test(v) || ((locale == 'en') ? 'E-mail must be valid' : 'E-mail deve ser válido'),
      ]
      return data
    },
    passwordRules () {
      let locale = this.$i18n.locale
      let data = [
        v => !!v || ((locale == 'en') ? 'Field is required' : 'Campo é obrigatório'),
        v => (v && v.length >= 8) || ((locale == 'en') ? 'Password must be less than 8 characters' : 'A senha deve ter menos de 8 caracteres'),
      ]
      return data
    },
  },
  methods: {
    avImage () {
      let btnFile = document.querySelector('.v-file-input__text')
      btnFile.click()
    },
    loadImage () {
      let file = document.querySelector('input[type=file]')['files'][0];
      let reader = new FileReader();
      reader.onload = function (e) {
        localStorage.setItem('img', e.currentTarget.result)
      }
      reader.readAsDataURL(file)
      setTimeout(() => {
        this.avatarSelected = localStorage.getItem('img')
      }, 500);

    },
    closeProfile () {
      this.currentPassword = ''
      this.newPassword = ''
      this.confirmPassword = ''
      store.dispatch('profile/statusProfile', false)
    },
    setProfile () {
      if (this.$refs.form1.validate()) {
        let params = store.getters['profile/profile']
        params['avatar'] = this.avatarSelected
        store.dispatch('profile/updateProfile', params)
      }
    },
    changePassword () {
      if (this.$refs.form2.validate() && this.newPassword === this.confirmPassword) {
        let params = {
          "old_password": this.currentPassword,
          "new_password1": this.newPassword,
          "new_password2": this.confirmPassword
        }
        store.dispatch('profile/changePassword', params)
        this.currentPassword = ''
        this.newPassword = ''
        this.confirmPassword = ''
      }
    }
  },
}
</script>
<style scoped>
.v-tabs.tabs .v-icon {
  margin-right: 5px;
}

.av-btn {
  position: absolute;
  margin-top: 60px;
  left: 100px;
}
.av-btn i {
  font-size: 20px !important;
}
.av-file {
  display: none;
}
.avatar {
  /* border: solid 6px rgb(20, 90, 196); */
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.226);
}
</style>